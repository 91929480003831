<template>
  <ContactViewCreate></ContactViewCreate>
</template>

<script>
import ContactViewCreate from "@/components/MyAcademy/Content/ContactView/Create.vue";
export default {
  components: {
    ContactViewCreate,
  },
};
</script>

<style></style>
